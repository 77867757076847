import axios,{post,get} from '@/axios'


export const getUserInfo = (params)=>{
  return get("/basedata/user/getUserInfo",params)
}

export const getRouters = (params)=>{
  return get("/basedata/menu/getRouters?systemCode=AI",params)
}


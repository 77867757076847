"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.default = createCopyCodePreview;

var _copyToClipboard = _interopRequireDefault(require("copy-to-clipboard"));

function isCopyButton(el) {
  return el.classList.contains('dlm-copy-code-btn');
}

function findCodeWrapperEl(el) {
  if (el.classList.contains('v-md-pre-wrapper')) {
    return el;
  }

  return findCodeWrapperEl(el.parentNode);
}
// v-md-pre-wrapper v-md-pre-wrapper-html extra-class
function getPreviewEl(el) {
  // var previewElClass = 'v-md-editor-preview';
  
  var previewElClass = 'v-md-pre-wrapper';
  return el.classList.contains(previewElClass) ? el : el.querySelectorAll("." + previewElClass);
}

function createCopyCodePreview() {
  return {
    install: function install(VMdEditor) {
      if (!VMdEditor.mixins) VMdEditor.mixins = [];
      VMdEditor.mixins.push({
        emits: ['copy-code-success'],
        mounted: function mounted() {
          this.handleCopyCodeInit()
        },
        beforeUnmount: function beforeUnmount() {
          var previewEl = getPreviewEl(this.$el);
          if(previewEl && previewEl.length>0){
            previewEl.forEach(element => {
              element.removeEventListener('click', this.handleCopyCodeClick);
            });
          }
        },
        methods: {
          handleCopyCodeInit(){
            var _this = this;
            this.$nextTick(function () {
              var previewEl = getPreviewEl(_this.$el);
              if(previewEl && previewEl.length>0){
                previewEl.forEach(element => {
                  var span = document.createElement('span');
                  var i = document.createElement('span');
                  i.innerHTML="复制";
                  i.classList.add("dlm-copy-code-btn-i");
                  span.classList.add("dlm-copy-code-btn");
                  span.classList.add("iconfont");
                  span.innerHTML= "&#xe8b0;"
                  span.appendChild(i);
                  element.appendChild(span);
                  element.addEventListener('click', _this.handleCopyCodeClick);
                });
              }
            });
          },
          handleCopyCodeClick: function handleCopyCodeClick(_ref) {
            var target = _ref.target;
            if (isCopyButton(target)) {
              var codeWrapper = findCodeWrapperEl(target);
              
              if (codeWrapper) {
                var code = codeWrapper.querySelector('code').innerText;
                (0, _copyToClipboard.default)(code);
                this.$emit('copy-code-success', code);


                target.querySelector('.dlm-copy-code-btn-i').innerHTML="已复制";
                setTimeout(()=>{
                  target.querySelector('.dlm-copy-code-btn-i').innerHTML="复制";
                },1000)
                
              }
            }
          }
        }
      });
    }
  };
}
import {
  getRouters
} from '@/api/menu'
import store from '@/store'
import router from '@/router'

function getPaths(list, res = []) {
  if (list.length == 0) {
    return [];
  }
  list.forEach(item => {
    res.push(item.path);
    if (item.children && item.children.length > 0) {
      getPaths(item.children, res)
    }
  })
  return [...new Set(res)];
}

function getRouterList() {
  getRouters().then(res => {
    let {
      data
    } = res;
    if (data.length > 0 && data[0].children.length > 0) {
      let paths = getPaths(data[0].children)
      store.commit("menu/mMenuList", data[0].children)
      store.commit("menu/mPaths", paths)
      store.commit("mLoadRouterFinished", 1)
      initRouter(paths)
    } else {
      store.commit("mLoadRouterFinished", 2)
      initRouter([])
      router.replace("/401")
      
    }
  }).catch(res => {
    if (res && res.resCode != "001106") {
      store.commit("mLoadRouterFinished", 2)
      initRouter([])
    }
  })
}
getRouterList();

function initRouter(paths = []) {
  let pathname = location.pathname;
  let fullPath = location.pathname + location.search;
  if (paths.length > 0) {
    if (paths.indexOf(pathname) !== -1) {
      router.replace(fullPath)
    } else {
      router.replace(paths[0])
    }
  } else {
    router.replace(fullPath)
  }

}


export default getRouterList;
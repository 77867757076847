import iconData from "@/assets/icon/iconfont.json";
export function praseIconfont(str) {
  if (str) {
    let data = {}
    for (var i = 0; i < iconData.glyphs.length; i++) {
      data = iconData.glyphs[i]
      if (str == data.icon_id) {
        return data.unicode
      }
    }
  }
  return str
}
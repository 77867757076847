import router from '@/router'

export const ssoAuth = (params = {}) => {
  if(location.search.indexOf("ticket") != -1){
    return
  }
  router.replace({
    path: '/loginVerification?redirect=' + location.href,
  })
}

import axios,{post} from '@/axios'


export const login = (params)=>{
  return post("/login",params)
}

export const fsLogin = (params)=>{
  return post("/fslogin",params)
}

export const logout = (params)=>{
  return post("/logout",params)
}
export const loginByTicket = params => {
  return post("/loginByTicket",params)
};
